.container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .topSectionContainer {
    display: flex;
    align-items: center;

    .starIcon {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.2rem;

      path {
        fill: var(--color-basil);
      }
    }

    .percentageLabel {
      font-size: 0.75rem;
      color: var(--color-basil);
      text-transform: uppercase;
    }
  }

  .progressBar {
    margin-top: 0.3rem;
  }
}

@import 'styles/mixins';

$filters-height: 3.5rem;

.filtersContainer {
  background-color: var(--color-white);
  position: sticky;
  padding: 1rem 2rem;
  width: 100%;
  z-index: 2;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 80px;
  height: $filters-height;

  @include mobile {
    padding: 0;
    top: 0;
  }
}

.bottomContainer {
  display: flex;

  .feed {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0 2rem 1rem;

    @include mobile {
      padding: 0 0 5rem;
    }
  }

  .mapContainer {
    position: sticky;
    top: calc(80px + #{$filters-height});
    height: calc(100vh - 80px - #{$filters-height});
    flex: 1;
    border-radius: 1rem;
    overflow: hidden;

    .map {
      width: 100%;
      height: 100%;
    }

    @include mobile {
      display: none;
    }
  }
}

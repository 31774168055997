.buttonContainer {
  display: flex;
  justify-content: center;
  user-select: none;

  .icon {
    display: flex;
    border-radius: 100rem;
    background-color: var(--color-fog);
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    padding: 0.2rem;

    svg {
      fill: var(--color-raven)
    }

    &.rightIcon {
      rotate: 180deg;
    }

    &.disabled {
      cursor: unset;
      opacity: 0;
    }
  }
}

.container {
  z-index: 1;
  height: 100%;
  width: 100%;

  .marker {
    cursor: pointer;
  }
}

.tooltip {
  border-radius: 0.5rem;
  overflow: hidden;

  & > div:last-child {
    padding: 0;
    border-radius: 0.5rem;
  }
}

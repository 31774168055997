@import "styles/mixins";


.drawer {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem 2rem;

  .title {
    font-size: 0.85rem;
    letter-spacing: 0.2px;
    color: var(--color-raven);
  }

  .chipsContainer {
    flex: 1;

    .chips {
      margin-top: 1rem;
      margin-bottom: 2rem;

      @include mobile {
        row-gap: 0.5rem;
        margin-top: 0.6rem;
        margin-bottom: 0.6rem;
      }
    }
  }
}

@import "styles/mixins";

.container {
  padding-top: 0.5rem;
  width: 100%;

  .topContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .title {
      font-weight: 600;
      font-size: 1.3rem;

      @include mobile {
        margin: 0.5rem 0 0 1.5rem;
      }
    }
  }

  .carouselContainer {
    @include mobile {
      padding: 0 1.5rem;
    }

    .carousel {
      overflow: visible;
      width: 100%;
      display: flex;

      .image {
        border-radius: 1rem;
        margin-bottom: 0.3rem;
      }

      .marketTitle {
        font-weight: 600;
        font-size: 0.9rem;
      }

      .marketSubtitle {
        font-size: 0.8rem;
        color: var(--color-concrete);
      }
    }
  }
}
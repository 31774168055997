@import "styles/mixins";

.container {
  background-image: url("../../../../../public/assets/illustrations/black_friday.png");


  @include mobile {
    padding: 1rem;
    border-radius: 0;
  }

  .link {
    color: var(--color-white);
    text-decoration: underline;
  }

  .title {
    display: inline-block;
    font-size: 2rem;
    color: var(--color-white);
    font-weight: 400;

    @include mobile {
      font-size: 1.2rem;
    }

    .lime {
      display: inline-block;
      color: var(--color-white);
      font-family: var(--font-literata), sans-serif;
      font-weight: 400;
      font-style: italic;
      font-size: 2.2rem;
    }
  }
}

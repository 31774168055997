@import "styles/mixins";

.container {
  width: 100%;
  overflow: hidden;

  .eventBanner {
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .carousel {
    overflow: hidden;
    width: 100%;
    margin-bottom: 1.5rem;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
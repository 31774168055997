.outerDiv {
  width: 100%;

  .grid {
    --breakpoint-grid-columns: 1;

    grid-template-columns: var(
      --breakpoint-grid-column-template,
      repeat(var(--breakpoint-grid-columns, 1), minmax(0, 1fr))
    );
    grid-auto-flow: row dense;
    justify-content: center;
    gap: 1.5rem;
    display: grid;
    width: 100%;
    @media (max-width: 549px) {
      padding: 0 1.5rem;
    }
    @media (min-width: 1500px) {
      --breakpoint-grid-columns: 3;
    }
    @media (min-width: 950px) and (max-width: 1499px) {
      --breakpoint-grid-columns: 2;
    }
    @media (min-width: 800px) and (max-width: 949px) {
      --breakpoint-grid-columns: 1;
    }
  }

  .feedItem {
    border-radius: 8px;
    box-shadow: 0 5px 10px -6px #51515175;
  }

  .loadMore {
    margin-top: 1rem;
    text-align: center;
  }
}

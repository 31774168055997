
.container {
  display: flex;
  background-color: white;
  padding: 0.3rem 0.5rem;
  border-radius: 1rem;
  box-shadow: 0 1px 7px 1px #ccc;

  .loader {
    padding: 0.2rem 0;
  }
}
@import "styles/mixins";

.container {
  background-image: url("../../../../../public/assets/illustrations/lend_discount_event_banner.png");
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  padding: 2rem;
  background-size: cover;

  @include mobile {
    padding: 1rem;
    border-radius: 0;
  }

  .timer {
    width: fit-content;
    background-color: var(--color-lime);
    padding: 0.7rem 0.8rem;
    border-radius: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;

    @include mobile {
      font-size: 0.8rem;
      margin-bottom: 0.8rem;
    }
  }

  .title {
    display: inline-block;
    font-size: 2rem;
    color: var(--color-white);
    font-weight: 500;

    @include mobile {
      font-size: 1.2rem;
    }

    .lime {
      display: inline-block;
      color: var(--color-lime);
      font-family: var(--font-literata), sans-serif;
      font-weight: 600;
      font-style: italic;
    }
  }
}
.text {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--color-iron);
}

.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
}

.occupiedIcon {
  fill: var(--color-black);
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
}

.evictionContainer {
  background: var(--color-shell);
  color: var(--color-rust);
  padding: 0.2rem 0.7rem;
  border-radius: 2rem;

  .evictionIcon {
    fill: var(--color-rust);
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.125rem 0;

  div:first-child {
    flex: 1;
  }

  div:last-child {
    flex: 1;
    text-align: right;
  }

  .escIcon {
    width: 0.75rem;
    height: 0.75rem;
    cursor: pointer;
  }

  .label {
    font-size: 0.8125rem;
  }

  .resetButton {
    width: unset;
    font-weight: 400;
    border-radius: 25rem;
    font-size: 0.8125rem;
    padding: 0.125rem 0.5rem;
    color: var(--color-iron);
    background-color: var(--color-fog);

    &:disabled {
      opacity: 0.3;
    }
  }
}

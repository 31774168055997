.container {
  width: 4.5rem;
  height: 0.25rem;
  border-radius: 25rem;
  background-color: var(--color-fog);

  .progress {
    height: 100%;
    border-radius: 25rem;
    background-color: var(--color-basil);
  }
}

.container {
  width: 220px;
  border-radius: 0.5rem;
  overflow: hidden;
}

.residentsContainer {
  width: 280px;
  height: 320px;
  padding: 5px;
}

.arrowIcon {
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.2rem;
  transform: rotate(-90deg);

  path {
    fill: var(--color-basil);
  }

  &.negative {
    transform: unset;

    path {
      fill: var(--color-rust);
    }
  }
}

.percentageLabel {
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  margin-bottom: 0.5rem;
  color: var(--color-basil);
  text-transform: uppercase;

  &.negative {
    color: var(--color-rust);
  }
}

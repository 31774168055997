.container {
  display: flex;
  flex-direction: column;
  padding: 0.7rem;

  .topSection {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .title {
      font-weight: 700;
      font-size: 0.72rem;
      margin-bottom: 0.4rem;
      color: var(--color-black);
    }

    .stockPrice {
      font-weight: 700;
      font-size: 0.72rem;
      color: var(--color-black);
      margin-bottom: 0.375rem;
    }
  }

  .bottomSection {
    display: flex;
    flex-direction: column;

    .subtitle {
      align-items: center;
      font-size: 0.72rem;
      font-weight: 400;
      color: var(--color-iron);
    }
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 0.6rem;
  }
}

.residentsContainer {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  min-width: 0;

  .residentsElipses {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .residentsTitle {
    font-weight: 700;
    font-size: 0.72rem;
    margin-bottom: 0.5rem;
    color: var(--color-black);
  }

  .residentsSubtitle {
    font-size: 0.72rem;
    font-weight: 400;
    color: var(--color-iron);
  }

  .residentsLeftSection {
    width: 0;
    flex: 1;
  }

  .residentsRightSection {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0.5rem;
  }
}

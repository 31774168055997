@import "styles/mixins";

.filterChip {
  @include mobile {
    filter: drop-shadow(0 5px 7px rgba(0 0 0 / 10%));
    background-color: var(--color-white);
    padding: 0.5rem;
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    z-index: 2;

    svg {
      width: 1.5rem !important;
      height: 1.5rem !important;
      margin-right: 0 !important;
    }
  }

  &::after {
    content: 'Filter';
    @include mobile {
      content: none;
    }
  }

  .filterContainer {
    position: relative;

    .filterIcon {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      vertical-align: middle;
      fill: var(--color-black);
    }

    .count {
      background-color: var(--color-black);
      transform: translate(-35%, -25%);
      color: var(--color-white);
      border-radius: 25rem;
      font-size: 0.625rem;
      position: absolute;
      padding: 2.5px;
      right: 0;
      top: 0;
    }
  }
}

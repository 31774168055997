.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 1px 7px 1px #ccc;

  .text {
    font-size: 1rem;
    font-weight: 600;
  }
}
.propertyItem {
  cursor: pointer;

  .imageContainer {
    aspect-ratio: 1 / 1;

    .propertyImage {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      margin-bottom: 1rem;
      border-radius: 0.75rem;
    }
  }
}

@import "styles/mixins";

.container {
  display: flex;
  align-items: center;
  transition: width 0.25s;
  justify-content: space-between;
  width: 100%;

  @include mobile {
    flex-direction: column;

    .chipsSelector {
      width: 100vw;
      padding: 1rem 1.5rem;
      overflow: auto;
      flex-wrap: unset;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .unselectedChip {
    border: 1px solid var(--color-pearl);
  }
}

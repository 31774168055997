.imageContainer {
  aspect-ratio: 2 / 1;
  width: 220px;
  height: 110px;
  background-color: var(--color-cloud);

  .image {
    display: block;
    object-fit: cover;
    width: 220px;
    height: 110px;
  }

  .loader {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.residentsImageContainer {
  width: 270px;
  height: 245px;

  .image {
    display: block;
    width: 270px;
    height: 245px;
    border-radius: 0.5rem;
  }
}

.container {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  min-width: 0;
  margin-top: 10px;

  .title {
    font-weight: 700;
    font-size: 0.8125rem;
    margin-bottom: 0.5rem;
    color: var(--color-black);
  }

  .subtitle {
    display: flex;
    text-overflow: ellipsis;
    align-items: center;
    font-size: 0.8125rem;
    font-weight: 400;
    color: var(--color-iron);
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .leftSection {
    width: 0;
    flex: 1;
  }

  .rightSection {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .stockPrice {
      margin-bottom: 0.375rem;
    }

    .chart {
      width: 10rem;
    }
  }

  .residentRightSection {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .residentTitle {
    display: flex;
    text-overflow: ellipsis;
    align-items: center;
    font-size: 0.8125rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: var(--color-iron);
  }
}

.residentContainer {
  margin: 14px;
}

.residentsSubtitle {
  text-overflow: ellipsis;
  font-size: 0.8125rem;
  font-weight: 400;
  color: var(--color-iron);
}

.container {
  background-color: var(--color-white);
  min-width: 20rem;
  position: fixed;
  height: 100%;
  width: 30vw;
  right: 0;
  top: 0;
  z-index: 10;
}

.overlay {
  background-color: var(--color-black-50-percent);
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  left: 0;
  top: 0;
}

